import React from 'react'

import { FaMapMarkerAlt } from "react-icons/all";

export const MapMarker: React.FC<{ lat: number; lng: number; text: string; aqi: number; selected?: boolean, onClick?: () => void }> =
  ({ text, selected, aqi, onClick }) => {
    return (
      <>
        <div className="hover:text-gray-800 text-transparent font-bold hover:shadow-lg text-center" onClick={onClick}>
          <span
            className={`absolute rounded-full h-7 w-7 top-0 bg-transparent border-2 text-gray-200 border-white ${
              !!selected ? "animate-ping" : ""
            }`}
          ></span>
          <div
            className={`flex flex-row justify-center items-center z-10 rounded-full w-7 h-7 bg-green-600 text-gray-200 border-2 border-white ${
              !!selected ? "" : ""
            }`}
          >
            
          </div>
          {text}
        </div>
      </>
    );
  };

export const MapMarkerMemoized = React.memo(MapMarker);